import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionClient, UserClient } from 'src/app/portal-api.g';
import { ValueObserver } from 'src/app/_base/value.observer';
import { PortalEvents } from 'src/app/_events/portal.events';
import { ConfigService } from 'src/app/_services/config.service';
import { PortalTranslatorService } from 'src/app/_services/portal-translation.service';

@Component({
  selector: 'app-upgrade-widget',
  templateUrl: './upgrade-widget.component.html',
  styleUrls: ['./upgrade-widget.component.scss']
})
export class UpgradeWidgetComponent implements OnInit {

  @ViewChild('saasToCt') saasToCtButton: ElementRef;

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCtToSaas = false;
  public showSaasToCt = false;
  public showSaasFreeToPro = false;
  public showSaasToPro = false;
  public ctUrl: string;

  private subscriptionId: string;
  private organizationId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subscriptionClient: SubscriptionClient,
    private portalEvents: PortalEvents,
    public translator: PortalTranslatorService,
    private config: ConfigService) { }

  ngOnInit() {
    this.isLoading.subscribe(isLoading => {
      if (isLoading) {
        if (this.saasToCtButton) {
          this.translator.translate("one_moment_please").then(val => {
            this.saasToCtButton.nativeElement.innerText = val;
          })
        }
      }
      else {
      }
    });

    this.portalEvents.subscription.listen(sub => {
      this.subscriptionId = sub.id;
    });

    this.portalEvents.organization.listen(org => {
      this.organizationId = org.id;
    });

    this.translator.activeLanguage.listen(lang => {
      if (lang === 'fr') {
        this.ctUrl = this.config.config.api.modules.contactTracing.baseUrl.fr
      }
      else {
        this.ctUrl = this.config.config.api.modules.contactTracing.baseUrl.en
      }

    });

    this.portalEvents.userType.listen(val => {
      // For now if user has saas free and CT services, we don't show anything because we dont offer more as of right now.
      if (val.contactTracing && val.saasFree) {
        this.showCtToSaas = false;
        this.showSaasToCt = false;
      }
      else if (val.contactTracing) {
        this.showCtToSaas = true;
      }
      else if (val.saasFree || val.saasPaid) {
        this.showSaasToCt = true;
      }
    });
  }

  convertSaasToCt() {
    this.isLoading.next(true);
  }

  convertCtToSaas() {
    this.subscriptionClient.convertContactTracingSubscriptionToOrganization(this.organizationId).subscribe(vm => {

      // Announce to subscribers that the user has now CT
      this.portalEvents.userType.value.saasFree = true;

      window.location.href = this.config.config.signup.baseUrl;
    })
  }
}
